import { styled } from '@mui/system'
import Button from '../../molecules/button'

const ButtonIcon = styled(Button)`
	padding: 0;
	.MuiSvgIcon-root {
		color: ${({ theme }) => theme.palette.primary.main};
	}
`

const Divider = styled('span')`
	background-color: ${({ theme }) => theme.palette.grey.light};
	height: 26px;
	width: 1px;
`

export { ButtonIcon, Divider }
