import { memo } from 'react'
import { useRouter } from 'next/router'
import { NoSsr, Popper } from '@mui/material'
import Shadow from '../../atoms/shadow'
import { useCatalogUrl } from '@bluheadless/ui-logic/src/hooks/useCatalogUrl'
import { FormattedMessage } from 'react-intl'
import { cx } from '@emotion/css'
import {
	DropDownWrapper,
	DropDrownList,
	DropDrownListItem as DropDrownListItemStyled,
	NavLinkStyled,
	AdditionalBlock,
	ChildrenWrapper,
} from './menu-dropdown.styled'
import { array, bool, node, number, string, arrayOf, shape, object } from 'prop-types'
import RichContent from '../../particles/rich-content'
import { menuItemIsActive, menuHasActiveItems } from '@bluheadless/ui-logic/src/utils/menu'
import isEqual from 'lodash/isEqual'
import { useTrackingsActions } from '@bluheadless/ui-logic/src/hooks/trackings'

const DropDrownListItem = memo(
	function DropDrownListItem({ item, className, children }) {
		const { level, cssClasses } = item
		const { asPath } = useRouter()
		const active = menuItemIsActive(item, asPath)
		const hasActiveChildren = menuHasActiveItems(item.children, asPath)

		return (
			<DropDrownListItemStyled
				type={item.type}
				countItems={item.children?.length}
				className={cx(className, 'menu-subitem', 'level-' + level, cssClasses, {
					'menu-item-current': active || hasActiveChildren,
				})}
				disableGutters
				disablePadding
			>
				{children}
			</DropDrownListItemStyled>
		)
	},
	(prevProps, nextProps) => {
		return isEqual(prevProps.item, nextProps.item)
	}
)

function generateGetBoundingClientRect(x = 0, y = 0) {
	return () => ({
		width: 0,
		height: 0,
		top: y,
		right: x,
		bottom: y,
		left: x,
	})
}

const NavLink = ({ type, url, title, className, ...props }) => {
	const productUrl = useCatalogUrl(url)

	let href

	if (type === 'product') {
		href = productUrl
	} else {
		href = url
	}

	return title?.props?.id || title?.props?.content ? (
		<NavLinkStyled href={href} className={className} {...props}>
			{title}
		</NavLinkStyled>
	) : null
}

const virtualElement = {
	getBoundingClientRect: generateGetBoundingClientRect(),
}

const handleMouseEnter = (className) => {
	if (className?.length > 0) {
		const triggerClassName = className?.split(' ')?.find((className) => className.startsWith('hover-trigger'))
		const defaultTriggerClassName = className
			?.split(' ')
			?.find((className) => className.startsWith('default-hover-trigger'))
		const defaultItem = document.getElementsByClassName('banner-' + defaultTriggerClassName)?.[0]
		const itemToShow = document.getElementsByClassName('banner-' + triggerClassName)?.[0]

		if (defaultItem) {
			defaultItem.classList.add('_hide')
			if (!itemToShow) {
				defaultItem.classList.remove('_hide')

				const activatedItems = document.querySelectorAll("[class*='banner-hover-trigger']")
				if (activatedItems?.length > 0) {
					activatedItems?.forEach(function (el) {
						el.classList.remove('_show')
					})
				}
			}
		}

		if (itemToShow) {
			const activatedItems = document.querySelectorAll("[class*='banner-hover-trigger']")
			if (activatedItems?.length > 0) {
				activatedItems?.forEach(function (el) {
					if (el.classList.contains('_show')) {
						el.classList.remove('_show')
					}
				})
			}

			itemToShow.classList.add('_show')
		}
	} else {
		return {}
	}
}

const handleMouseLeave = () => {
	const activatedItems = document.querySelectorAll("[class*='banner-hover-trigger']")
	const defaultItem = document.querySelectorAll("[class*='banner-default-hover-trigger']")

	if (defaultItem?.length > 0) {
		defaultItem?.forEach(function (el) {
			el.classList.remove('_hide')
		})
	}

	if (activatedItems?.length > 0) {
		activatedItems?.forEach(function (el) {
			el.classList.remove('_show')
		})
	}
}

const MenuDropDown = ({
	items,
	open,
	anchor,
	dropDownWrapperProps,
	additionalContent,
	parentCssClasses,
	parentMaxItemsForColumn,
	...props
}) => {
	const { menuItemClick } = useTrackingsActions()

	const dropDownRender = (items, maxItemsForColumn, subParentCssClasses) => {
		const renderMenu = (items, subParentCssClasses) => {
			// FIX for error: should have unique key
			const keyIds = items?.map((item) => item?.id)?.join('-') || ''
			return (
				<DropDrownList
					disablePadding
					countItems={items?.length}
					className={cx(
						'DropDrownList-root',
						parentCssClasses?.length > 0 || subParentCssClasses?.length > 0
							? 'parent_' + (parentCssClasses || subParentCssClasses)
							: null
					)}
					key={'menu-' + keyIds}
					parentMaxItemsForColumn={parentMaxItemsForColumn}
				>
					{items?.map((item, index) => {
						const {
							children,
							cssClasses,
							id,
							maxItemsForColumn,
							url,
							urlKey,
							path,
							target,
							title,
							type,
							content,
							entityId,
							identifier,
						} = item

						return (
							<DropDrownListItem
								key={id}
								item={item}
								className={cx(
									'DropDrownList-item',
									children?.length > 0 ? 'hasChildren' : undefined,
									content ? 'hasBanner' : undefined
								)}
								position={index}
							>
								{type !== 'cms_block' ? (
									<NavLink
										type={type}
										url={path ?? urlKey ?? url}
										underline="none"
										variant={children?.length > 0 ? 'h4' : 'body2'}
										target={target}
										className={cx(cssClasses, {
											'no-link': !path && !urlKey && !url,
											hasBannerAsFirstItem:
												children?.length > 0 && children.find((child) => child?.cssClasses?.includes('first-item')),
											last: items[items.length - 1].id === id && !children?.length,
										})}
										title={<RichContent content={title} />}
										onMouseEnter={() => handleMouseEnter(cssClasses)}
										onClick={(e) => {
											menuItemClick({ event: e, entityType: type, entityId, entityName: identifier })
										}}
									/>
								) : (
									<RichContent content={content} />
								)}
								{children?.length > 0 && dropDownRender(children, maxItemsForColumn, cssClasses)}
								{children?.length > 0 && (path ?? urlKey ?? url) ? (
									<NavLink
										title={<FormattedMessage id="menu_view_all" />}
										aria-label={<FormattedMessage id="menu_view_all" />}
										className={cx('item-view-all')}
										type={type}
										url={path ?? urlKey ?? url}
										target={target}
										variant="body2"
										underline="none"
										onClick={(e) => {
											menuItemClick({ event: e, entityType: type, entityId, entityName: identifier })
										}}
									/>
								) : null}
							</DropDrownListItem>
						)
					})}
				</DropDrownList>
			)
		}

		const menu = (items, subParentCssClasses) => {
			let children = []

			if (maxItemsForColumn) {
				const columns = Math.ceil(items?.length / maxItemsForColumn)
				for (let i = 0; i < columns; i++) {
					children.push(
						renderMenu(items.slice(i * maxItemsForColumn, (i + 1) * maxItemsForColumn), subParentCssClasses)
					)
				}
			} else {
				children = renderMenu(items, subParentCssClasses)
			}

			return <ChildrenWrapper>{children}</ChildrenWrapper>
		}

		return menu(items, subParentCssClasses)
	}

	return (
		<NoSsr>
			<Popper anchorEl={anchor ?? virtualElement} open={open} {...props}>
				<Shadow elevation={7} square sx={{ width: '100%' }}>
					<DropDownWrapper
						{...dropDownWrapperProps}
						className={parentCssClasses}
						parentMaxItemsForColumn={parentMaxItemsForColumn}
						onMouseLeave={() => handleMouseLeave()}
					>
						{dropDownRender(items, null)}
					</DropDownWrapper>
					{additionalContent && <AdditionalBlock>{additionalContent}</AdditionalBlock>}
				</Shadow>
			</Popper>
		</NoSsr>
	)
}

MenuDropDown.defaultProps = {
	keepMounted: true,
	disablePortal: true,
	popperOptions: {
		strategy: 'fixed',
	},
}

MenuDropDown.propTypes = {
	items: arrayOf(
		shape({
			id: number,
			title: node,
			type: string,
			url: string,
			children: array,
		})
	).isRequired,
	open: bool,
	additionalContent: node,
	dropDownWrapperProps: object,
}

export default MenuDropDown
