import { styled } from '@mui/system'
import Typography from '../../atoms/typography'
import BHLink from '../link'
import IconHeadArrowDown from '../../atoms/icons/arrowhead-down-icon'

const shouldForwardProp = (prop) => !['columns', 'toggleDropdown', 'routeChanging', 'iconDropdownProps'].includes(prop)

const NavMenu = styled('nav', { shouldForwardProp })`
	display: grid;
	grid-template-rows: 1fr;
	grid-template-columns: repeat(${({ columns }) => columns}, max-content);
	grid-gap: 27px;
	pointer-events: ${({ routeChanging }) => (routeChanging ? 'none' : 'auto')};
`

const NavItem = styled(Typography)`
	display: flex;
	flex: 1 0 auto;
	align-items: center;
	color: ${({ theme }) => theme.palette.primary.main};
	font-size: ${({ theme }) => theme.typography.pxToRem(12)};
	text-transform: uppercase;

	> .MuiSvgIcon-root {
		transition: transform 0.3s;
		transition-timing-function: ${({ theme }) => theme.transitions.easing.easeOut};
		color: ${({ theme }) => theme.palette.primary.main};
	}
	&:hover {
		text-decoration: none;
		color: ${({ theme }) => theme.palette.primary.main};
		a:after {
			width: 100%;
		}
	}
	&.nav-dropdown-opened {
		color: ${({ theme }) => theme.palette.primary.main};
		text-decoration: none;
		.MuiSvgIcon-root {
			transform: rotate3d(0, 0, 1, -180deg);
		}
		a:after {
			background-color: ${({ theme }) => theme.palette.primary.main};
		}
	}
	&.nav-active {
		a:after {
			width: 100%;
		}
	}
`

const LinkRoot = styled('span')`
	display: flex;
	align-items: center;
	text-decoration: none;
	a {
		text-decoration: none;
	}
`
const Link = styled(BHLink, { shouldForwardProp })`
	color: inherit;
	cursor: pointer;
	padding: 0;
	display: inline-block;
	line-height: 18px;
	transition: color 0.2s;
	transition-timing-function: ${({ theme }) => theme.transitions.easing.easeOut};
	&:after {
		content: '';
		display: block;
		width: 0px;
		height: 1px;
		background-color: ${({ theme }) => theme.palette.primary.main};
		margin-top: -2px;
		transition: width 0.3s;
		transition-timing-function: ${({ theme }) => theme.transitions.easing.easeOut};
	}
`

const IconDropdown = styled(IconHeadArrowDown)`
	cursor: pointer;
	display: none;
	visibility: hidden;
`

export { NavMenu, NavItem, LinkRoot, Link, IconDropdown }
